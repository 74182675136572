import {
  Box,
  Breadcrumbs,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  addDoc,
  getDocs,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./tasks.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DoneIcon from "@mui/icons-material/Done";
import successEmodji from "../../assets/success-emodji.png";

const Disk = ({ navigation, route }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ebf2fc",
    boxShadow: 24,
    p: 2,
    border: "15px solid #ffffff",
  };

  const [auto, setAuto] = useState([]); // Автомобиль
  const [newAuto, setNewAuto] = useState([]); // Автомобиль
  const autoCollectionRef = collection(db, "auto");

  const [open, setOpen] = useState(false);
  const handleOpen = (props) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleOpenSuccess = (props) => setOpenSuccess(true);
  const handleCloseSuccess = () => setOpen(false);

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = (props) => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const usersCollectionRef = collection(db, "tasks");
  const [tasks, setTasks] = useState([]);

  const [headerTasks, setHeaderTasks] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [chipTask, setChipTask] = useState("");

  useEffect(() => {
    const getTasks = async () => {
      const data = await getDocs(usersCollectionRef);
      setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getTasks();
  }, []);

  useEffect(() => {
    const getAuto = async () => {
      const data = await getDocs(autoCollectionRef);
      setNewAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getAuto();
  }, []);

  const handleCar = (event) => {
    setAuto(event.target.value);
  };

  const now = new Date();
  const timestamp = Timestamp.fromDate(now);

  const createTask = async () => {
    try {
      const docRef = await addDoc(usersCollectionRef, {
        body_text: bodyText,
        header_tasks: headerTasks,
        gos: auto,
        statusTask: "Новая",
        dateCreateTask: timestamp,
        chipTask: chipTask,
      });

      // Форматирование сообщения
      const message =
        `📌 *Новая задача создана:*\n` +
        `*Заголовок:* ${headerTasks}\n` +
        `*Описание:* ${bodyText}\n` +
        `*Автомобиль:* ${auto}\n` +
        `*Статус:* ${chipTask}`;

      // Отправка сообщения в Telegram
      await sendTelegramMessage(message);

      window.location.reload();
    } catch (error) {
      console.error("Ошибка при создании задачи:", error);
      alert("Ошибка загрузки");
    }
  };

  const deleteTask = async (id) => {
    const userDoc = doc(db, "tasks", id);
    await deleteDoc(userDoc);
    if (deleteTask) {
      window.location.reload();
      alert("Задача удалена!");
    } else {
      alert("Ошибка загрузки");
    }
  };

  // Cохранение выполнения задания
  const updateOrder = async (id) => {
    const userDocId = doc(db, "tasks", id);
    await updateDoc(userDocId, {
      statusTask: "Выполнено",
    });
    if (updateOrder) {
      handleOpenSuccess();
    } else {
      alert("Ошибка загрузки");
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [selectedTask, setSelectedTask] = useState(null);
  const handleOpenN = (task) => {
    setSelectedTask(task);
    // Здесь может быть логика для открытия модального окна
  };
  const handleCloseN = () => setSelectedTask(false);

  const chipChange = (event) => {
    setChipTask(event.target.value);
  };

  const [sortedTasks, setSortedTasks] = useState([]);

  const formatDate = (date) => {
    return date
      .toLocaleString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", " в")
      .replace(":", ":");
  };

  useEffect(() => {
    const sorted = tasks
      .filter((task) => task.statusTask === "Новая") // Фильтрация задач по статусу
      .map((task) => ({
        ...task,
        date: new Date(task.dateCreateTask.seconds * 1000), // Преобразование в Date объект для каждой задачи
      }))
      .sort((a, b) => b.date - a.date); // Сортировка по убыванию дат
    setSortedTasks(sorted);
  }, [tasks]);

  const sendTelegramMessage = async (message) => {
    const botToken = "6402875221:AAGl94vHeiuR3J7C9uCf5674kXaWqBJ405w";
    const chatId = "-1001234567890"; // Замените на ваш chat_id группы
    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const payload = {
      chat_id: chatId,
      text: message,
      parse_mode: "Markdown",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (!data.ok) {
        console.error("Ошибка при отправке сообщения в Telegram:", data);
      } else {
        console.log("Сообщение успешно отправлено в Telegram");
      }
    } catch (error) {
      console.error("Ошибка при отправке сообщения в Telegram:", error);
    }
  };

  return (
    <div className="grid">
      <Header />
      <div class="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Typography color="text.primary">
              <b>Задачи</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div className="main_text_button">
          <p className="header-mt">Список задач</p>
        </div>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenAdd}
            className="btn-add-to-table"
          >
            Добавить
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
            as={Link}
            to={"/tasks_settings"}
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
        </div>
        <div className="grid-tasks">
          {sortedTasks.map((task) => (
            <div
              className="box-tasks"
              key={task.id}
              onClick={() => handleOpenN(task)}
            >
              <div className="container-chip">
                <p className="gos-task">{task.gos}</p>
                <p className="chip-action-task">
                  {(task.chipTask == "Cрочно" && (
                    <p className="chip-task-red">Cрочно</p>
                  )) ||
                    (task.chipTask == "Терпит" && (
                      <p className="chip-task-blue">Терпит</p>
                    )) ||
                    (task.chipTask == "На будущее" && (
                      <p className="chip-task-gray">На будущее</p>
                    )) ||
                    (task.chipTask == "Разбор" && (
                      <p className="chip-task-yellow">Разбор</p>
                    ))}
                </p>
              </div>
              <p className="main-header-tasks">{task.header_tasks}</p>
              <p className="main-bodytext-tasks">{task.body_text}</p>
              <div className="main-icon-tasks">
                <div>
                  <p className="mini-gray-text">{formatDate(task.date)}</p>
                </div>
                <div>
                  <Link onClick={() => updateOrder(task.id)} value={task.id}>
                    <DoneIcon className="icon-tasks-block-succes" />
                  </Link>
                  <Link onClick={() => deleteTask(task.id)}>
                    <DeleteOutlinedIcon className="icon-tasks-block-delete" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          keepMounted
          open={openSuccess}
          onClose={handleCloseSuccess}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <img src={successEmodji} className="icon-image-success" />
              <p className="modal-grid-succes-text">Задача закрыта</p>
            </Typography>
            <a href="/tasks" className="modal-grid-succes-text-a">
              Вернуться
            </a>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={selectedTask}
          onClose={handleCloseN}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <p className="modal-grid-succes-text f-gos">
                {selectedTask ? selectedTask.gos : ""}
              </p>
              <p className="modal-grid-succes-text f-body">
                {selectedTask ? selectedTask.body_text : ""}
              </p>
            </Typography>
            <a href="/tasks" className="modal-grid-succes-text-a">
              Отлично
            </a>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <HighlightOffIcon className="modal-grid-delete-icon" />
              <p className="modal-grid-succes-text">РАЗДЕЛ В РАЗРАБОТКЕ!</p>
            </Typography>
            <a href="/tasks" className="modal-grid-succes-text-a">
              Отлично
            </a>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style} className="box-modal-succes">
            <Typography
              className="modal-grid-succes"
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
            >
              <LibraryBooksIcon className="modal-grid-add-icon" />
            </Typography>
            <TextField
              id="outlined-basic"
              label="Заголовок"
              variant="outlined"
              className="select-inp-task"
              size="small"
              sx={{ width: 1 }}
              onChange={(event) => {
                setHeaderTasks(event.target.value);
              }}
            />
            <InputLabel id="simple-select-label" className="label-inp-task">
              Чип-статус
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="demo-simple-select"
              value={chipTask}
              defaultValue={"Бизнес"}
              onChange={chipChange}
              className="select-inp-task"
            >
              <MenuItem value={"Cрочно"}>Cрочно</MenuItem>
              <MenuItem value={"Терпит"}>Терпит</MenuItem>
              <MenuItem value={"На будущее"}>На будущее</MenuItem>
              <MenuItem value={"Разбор"}>Разбор</MenuItem>
            </Select>
            <InputLabel
              id="demo-simple-select-label"
              className="label-inp-task"
            >
              Автомобиль
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={auto}
              onChange={handleCar}
              helperText="Some important text"
              className="select-inp-task"
            >
              {newAuto.map((item) => (
                <MenuItem value={item.gos}>
                  {item.gos} - {item.model}
                </MenuItem>
              ))}
            </Select>
            <InputLabel
              id="demo-simple-select-label"
              className="label-inp-task"
            >
              Описание задачи
            </InputLabel>
            <TextareaAutosize
              id="outlined-basic"
              className="textArea_bodyText"
              label="Имя"
              variant="outlined"
              type="textarea"
              size="small"
              sx={{ width: 1 }}
              onChange={(event) => {
                setBodyText(event.target.value);
              }}
            />
            <Button
              variant="outlined"
              size="small"
              onClick={createTask}
              className="btn-create-task"
            >
              Сохранить
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Disk;

import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { updateDoc, doc, getDoc, setDoc } from "firebase/firestore";
import {
  Button,
  Modal,
  Select,
  Typography,
  MenuItem,
  Breadcrumbs,
  Link,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinearProgress from "@mui/material/LinearProgress";

const UpdateAuto = () => {
  const [fadeOut, setFadeOut] = useState(false); // Для управления анимацией исчезновения
  const { id } = useParams();
  const [data, setData] = useState({
    marka: "",
    model: "",
    vin: "",
    year: "",
    gos: "",
    drive: "",
    tireSeason: "",
    tireComment: "",
    tireFront: "",
    tireBack: "",
    statusAuto: "",
    stsNumber: "",
    stsOwner: "",
    stsDateStart: "",
    insuranceCompany: "",
    insuranceNumber: "",
    insuranceDateStart: "",
    insuranceDateEnd: "",
    inspectionСompany: "",
    inspectionDateStart: "",
    inspectionDateEnd: "",
    leasing: false,
    leasingTo: "",
    leasingBy: "",
    dateEndYandex: "",
    yaKontrolDate: "",
    commentAuto: "", // Новое поле для заметок
  });

  const {
    marka,
    model,
    gos,
    drive,
    tireSeason,
    tireComment,
    tireFront,
    tireBack,
    statusAuto,
    vin,
    year,
    stsNumber,
    stsOwner,
    stsDateStart,
    insuranceCompany,
    insuranceNumber,
    insuranceDateStart,
    insuranceDateEnd,
    inspectionСompany,
    inspectionDateStart,
    inspectionDateEnd,
    leasing,
    leasingTo,
    leasingBy,
    dateEndYandex,
    yaKontrolDate,
  } = data;

  const [loading, setLoading] = useState(false); // Для отображения загрузки
  const [progress, setProgress] = useState(0); // Для хранения прогресса

  useEffect(() => {
    if (id) {
      getsingleAuto();
    }
  }, [id]);

  const getsingleAuto = async () => {
    const docRef = doc(db, "auto", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      const docData = snapshot.data();
      setData({
        ...docData,
        stsDateStart: docData.stsDateStart
          ? docData.stsDateStart.toString()
          : "",
        insuranceDateStart: docData.insuranceDateStart
          ? docData.insuranceDateStart.toString()
          : "",
        insuranceDateEnd: docData.insuranceDateEnd
          ? docData.insuranceDateEnd.toString()
          : "",
        inspectionDateStart: docData.inspectionDateStart
          ? docData.inspectionDateStart.toString()
          : "",
        inspectionDateEnd: docData.inspectionDateEnd
          ? docData.inspectionDateEnd.toString()
          : "",
        dateEndYandex: docData.dateEndYandex
          ? docData.dateEndYandex.toString()
          : "",
        yaKontrolDate: docData.yaKontrolDate
          ? docData.yaKontrolDate.toString()
          : "",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setData({ ...data, [name]: checked });
  };

  const updateData = async () => {
    const docRef = doc(db, "auto", id);
    const snapshot = await getDoc(docRef);

    // Начало загрузки
    setLoading(true);
    setProgress(50); // Устанавливаем прогресс на 50% (или по ходу загрузки)

    const updatedData = {
      marka: marka || "",
      model: model || "",
      gos: gos || "",
      drive: drive || "",
      year: year || "",
      vin: vin || "",
      tireComment: tireComment || "",
      tireSeason: tireSeason || "",
      tireFront: tireFront || "",
      tireBack: tireBack || "",
      statusAuto: statusAuto || "",
      stsNumber: stsNumber || "",
      stsOwner: stsOwner || "",
      stsDateStart: stsDateStart ? String(stsDateStart) : "",
      insuranceCompany: insuranceCompany || "",
      insuranceNumber: insuranceNumber || "",
      insuranceDateStart: insuranceDateStart ? String(insuranceDateStart) : "",
      insuranceDateEnd: insuranceDateEnd ? String(insuranceDateEnd) : "",
      inspectionСompany: inspectionСompany || "",
      inspectionDateStart: inspectionDateStart
        ? String(inspectionDateStart)
        : "",
      inspectionDateEnd: inspectionDateEnd ? String(inspectionDateEnd) : "",
      leasing: leasing || false,
      leasingTo: leasingTo || "",
      leasingBy: leasingBy || "",
      dateEndYandex: dateEndYandex ? String(dateEndYandex) : "",
      yaKontrolDate: yaKontrolDate ? String(yaKontrolDate) : "",
      commentAuto: data.commentAuto || "", // Сохраняем заметки по авто
    };

    try {
      if (snapshot.exists()) {
        await updateDoc(docRef, updatedData);
      } else {
        await setDoc(docRef, updatedData);
      }

      setProgress(100);

      setTimeout(() => {
        setLoading(false); // Завершаем загрузку
        handleOpen(); // Открываем финальное модальное окно

        // Таймер для плавного исчезновения
        setTimeout(() => {
          setFadeOut(true); // Начинаем анимацию исчезновения
          setTimeout(() => {
            handleClose(); // Закрываем модальное окно
            setFadeOut(false); // Сбрасываем состояние для следующего раза
          }, 500); // Через 2 секунды после анимации закрываем модальное окно
        }, 500); // Через 2 секунды после успешной загрузки запускаем fadeOut
      }, 500);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Дашборд
            </Link>
            <Link underline="hover" color="inherit" href="/auto">
              Автомобили
            </Link>
            <Typography color="text.primary">
              <b>Создание автомобиля</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">{gos} - редактирование</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-add-to-table"
            onClick={updateData}
          >
            Сохранить
          </Button>
          <Button
            variant="outlined"
            size="small"
            as={Link}
            to={"/orders"}
            className="btn-add-to-table"
          >
            Отменить
          </Button>
          <div className="drivers-block-btn"></div>
        </div>
        <div className="box-block-edit-params grid-2column">
          <div class="grid-item">
            <p className="p-big-update m-top-0">Статус:</p>
            <div className="update-flex-grid">
              <p className="t-for-update">Статус авто:</p>
              <Select
                className="select-update styles-select"
                name="statusAuto"
                value={statusAuto}
                size="small"
                onChange={handleInputChange}
              >
                <MenuItem value="Работает">Работает</MenuItem>
                <MenuItem value="Простой">Простой</MenuItem>
                <MenuItem value="Ремонт">Ремонт</MenuItem>
                <MenuItem value="ДТП">ДТП</MenuItem>
                <MenuItem value="На продаже">На продаже</MenuItem>
                <MenuItem value="В Архив">В Архив</MenuItem>
              </Select>
            </div>
            <div container spacing={2} className="update-flex-grid">
              <p className="t-for-update">Очный осмотр Я.:</p>
              <input
                className="input-update-auto"
                type="date"
                name="yaKontrolDate"
                value={yaKontrolDate ? yaKontrolDate.slice(0, 10) : ""}
                onChange={handleInputChange}
              />
            </div>
            <p className="p-big-update ">Данные по автомобилю:</p>
            <div className="update-flex-grid">
              <p className="t-for-update">Марка:</p>
              <input
                className="input-update-auto"
                type="text"
                name="marka"
                value={marka}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Модель:</p>
              <input
                className="input-update-auto"
                type="text"
                name="model"
                value={model}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Гос.номер:</p>
              <input
                className="input-update-auto"
                type="text"
                name="gos"
                value={gos}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Год выпуска:</p>
              <input
                className="input-update-auto"
                type="text"
                name="year"
                value={year}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">VIN номер:</p>
              <input
                className="input-update-auto"
                type="text"
                name="vin"
                value={vin}
                onChange={handleInputChange}
              />
            </div>
            <p className="p-big-update">Резина:</p>
            <div className="update-flex-grid">
              <p className="t-for-update">Сезон:</p>
              <Select
                className="select-update styles-select"
                name="tireSeason"
                value={tireSeason}
                size="small"
                onChange={handleInputChange}
              >
                <MenuItem value="Лето">Лето</MenuItem>
                <MenuItem value="Зима">Зима</MenuItem>
              </Select>
            </div>

            <div className="update-flex-grid">
              <p className="t-for-update">Передняя ось:</p>
              <input
                className="input-update-auto"
                type="text"
                name="tireFront"
                value={tireFront}
                onChange={handleInputChange}
              />
            </div>

            <div className="update-flex-grid">
              <p className="t-for-update">Задняя ось:</p>
              <input
                className="input-update-auto"
                type="text"
                name="tireBack"
                value={tireBack}
                onChange={handleInputChange}
              />
            </div>

            <div className="update-flex-grid">
              <p className="t-for-update">Примечание:</p>
              <textarea
                className="input-update-auto"
                type="text"
                name="tireComment"
                value={tireComment}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div class="grid-item">
            <p className="p-big-update  m-top-0">
              Свидетельство о регистрации ТС:
            </p>
            <div className="update-flex-grid">
              <p className="t-for-update">СТС (серия и номер):</p>
              <input
                className="input-update-auto"
                type="text"
                name="stsNumber"
                value={stsNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Собственник:</p>
              <input
                className="input-update-auto"
                type="text"
                name="stsOwner"
                value={stsOwner}
                onChange={handleInputChange}
              />
            </div>

            <div className="update-flex-grid">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leasing}
                    onChange={handleCheckboxChange}
                    name="leasing"
                    color="primary"
                  />
                }
                label="Лизинг"
              />
            </div>
            {leasing && (
              <>
                <div className="update-flex-grid">
                  <p className="t-for-update">Лизинг на кого:</p>
                  <input
                    className="input-update-auto"
                    type="text"
                    name="leasingTo"
                    value={leasingTo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="update-flex-grid">
                  <p className="t-for-update">Лизинг по:</p>
                  <input
                    className="input-update-auto"
                    type="text"
                    name="leasingBy"
                    value={leasingBy}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}

            <p className="p-big-update">ОСАГО страховой полис</p>
            <div className="update-flex-grid">
              <p className="t-for-update">Страховая компания:</p>
              <input
                className="input-update-auto"
                type="text"
                name="insuranceCompany"
                value={insuranceCompany}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Серия/номер полиса:</p>
              <input
                className="input-update-auto"
                type="text"
                name="insuranceNumber"
                value={insuranceNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Дата начала:</p>
              <input
                className="input-update-auto"
                type="date"
                name="insuranceDateStart"
                value={
                  insuranceDateStart ? insuranceDateStart.slice(0, 10) : ""
                }
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Дата окончания:</p>
              <input
                className="input-update-auto"
                type="date"
                name="insuranceDateEnd"
                value={insuranceDateEnd ? insuranceDateEnd.slice(0, 10) : ""}
                onChange={handleInputChange}
              />
            </div>

            <p className="p-big-update">Диагностическая карта</p>
            <div className="update-flex-grid">
              <p className="t-for-update">Где делали осмотр:</p>
              <input
                className="input-update-auto"
                type="text"
                name="inspectionСompany"
                value={inspectionСompany}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Дата осмотра:</p>
              <input
                className="input-update-auto"
                type="date"
                name="inspectionDateStart"
                value={
                  inspectionDateStart ? inspectionDateStart.slice(0, 10) : ""
                }
                onChange={handleInputChange}
              />
            </div>
            <div className="update-flex-grid">
              <p className="t-for-update">Дата окончания:</p>
              <input
                className="input-update-auto"
                type="date"
                name="inspectionDateEnd"
                value={inspectionDateEnd ? inspectionDateEnd.slice(0, 10) : ""}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div>
            <p className="p-big-update m-top-0">Заметки по автомобилю:</p>
            <textarea
              className="input-update-auto h-150"
              name="commentAuto"
              value={data.commentAuto || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <Modal
          keepMounted
          open={open || loading}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              ...style,
              opacity: fadeOut ? 0 : 1, // Плавное изменение opacity
              transition: "opacity 2s", // Длительность анимации 2 секунды
            }}
            className="box-modal-succes"
          >
            {loading ? (
              <>
                <LinearProgress variant="determinate" value={progress} />
                <Typography className="t-center" sx={{ mt: 2 }}>
                  Загрузка на сервер...
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  className="modal-grid-succes"
                  id="keep-mounted-modal-description"
                  sx={{ mt: 2 }}
                >
                  <CheckCircleIcon className="modal-grid-succes-icon" />
                  <p className="modal-grid-succes-text">
                    Данные успешно загружены!
                  </p>
                </Typography>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default UpdateAuto;

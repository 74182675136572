import "./auto.css";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { collection, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import SettingsIcon from "@mui/icons-material/Settings";

const CalendarTO = () => {
  const usersCollectionRef = collection(db, "auto");
  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      setAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, []);

  const [repair, setRepair] = useState([]); // Автомобиль
  const repairCollectionRef = collection(db, "repair");
  useEffect(() => {
    const getRepair = async () => {
      const data = await getDocs(repairCollectionRef);
      setRepair(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getRepair();
  }, []);

  const createTechInsp = async () => {
    await addDoc(repairCollectionRef, {
      dateRepair: Timestamp.fromDate(new Date(dateRepair)),
      timeRepair: timeRepair,
      categoryRepair: categoryRepair,
      gos: auto, // гос.номер,
      mileage: mileage, // пробег,
      garage: service,
      commentRepair: commentRepair,
      commentRepairNext: commentRepairNext,
    });
    if (createTechInsp) {
      window.location.reload();
    } else {
      alert("ошибка загрузки");
    }
  };

  const autoCollectionRef = collection(db, "auto");
  const [auto, setAuto] = useState([]); // Автомобиль
  const [newAuto, setNewAuto] = useState([]); // Автомобиль
  const [mileage, setMileage] = useState(""); // Пробег
  const [dateRepair, setDateRepair] = useState(""); // Дата ремонта
  const [timeRepair, setTimeRepair] = useState(""); // Время заезда
  const [commentRepair, setCommentRepair] = useState("");
  const [commentRepairNext, setCommentRepairNext] = useState("");
  const [categoryRepair, setCategoryRepair] = useState(""); // Категория ремонта
  const changeCategory = (event) => {
    setCategoryRepair(event.target.value);
  };
  const [newServiceCenter, setServiceCenter] = useState([]); // Сервисный центр (вытащить из бд)
  const [service, setService] = useState([]); // Сервисный центр (запись)

  const serviceCollectionRef = collection(db, "group_partners");
  useEffect(() => {
    const getService = async () => {
      const data = await getDocs(serviceCollectionRef);
      setServiceCenter(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getService();
  }, []);

  const handleService = (event) => {
    setService(event.target.value);
  };

  const handleCar = (event) => {
    setAuto(event.target.value);
  };

  useEffect(() => {
    const getAuto = async () => {
      const data = await getDocs(autoCollectionRef);
      setNewAuto(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getAuto();
  }, []);

  const [currentWeekPeriod, setCurrentWeekPeriod] = useState(""); // Период текущей недели

  const getCurrentWeekPeriod = () => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek + 1
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek + 7
    );

    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(endDate);

    return `${startDateFormatted} - ${endDateFormatted}`;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const currentWeekPeriod = getCurrentWeekPeriod();
    setCurrentWeekPeriod(currentWeekPeriod);
  }, []);

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDayOfWeek + 1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDayOfWeek + 7
  );

  const [statusFilter, setStatusFilter] = useState("");

  const handleFilter = (status) => {
    setStatusFilter(status);
  };

  const filteredData = auto.filter((item) => {
    if (!statusFilter) {
      return true;
    }
    return item.statusAuto === statusFilter;
  });

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="grid">
      <Header />
      <div className="content">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <a
              className="breadcrumbs-first"
              href="/"
              underline="hover"
              color="inherit"
            >
              Дашборд
            </a>
            <Typography color="text.primary">
              <b>Календарь ТО</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <p className="header-mt">Календарь Тех.обслуживания</p>
        <div className="info-active-block-table">
          <Button
            variant="outlined"
            size="small"
            className="btn-settings-table"
          >
            <SettingsIcon className="icon-setting" />
          </Button>
          <div className="drivers-block-btn"></div>
          <input
            type="text"
            className="search-input-auto"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Поиск по гос.номеру"
          />
        </div>
        <table id="myTable">
          <thead>
            <tr>
              <th>След.ТО</th>
              <th>Прошлое ТО</th>
              <th>Пробег</th>
              <th>Гос.номер</th>
              <th>Cтатус</th>
              <th>Марка/Модель</th>
              <th>ТО</th>
              <th>Гараж</th>
              <th>МФ</th>
              <th>МП</th>
              <th>ВФ</th>
              <th>СФ</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {auto
              .filter((autoItem) =>
                autoItem.gos.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .sort((a, b) => {
                const matchedRepairsA = repair.filter(
                  (repairItem) =>
                    repairItem.gos === a.gos &&
                    repairItem.categoryRepair === "ТО"
                );
                const matchedRepairsB = repair.filter(
                  (repairItem) =>
                    repairItem.gos === b.gos &&
                    repairItem.categoryRepair === "ТО"
                );
                const repairsWithDateA = matchedRepairsA.map((repairItem) => ({
                  ...repairItem,
                  dateRepair: repairItem.dateRepair
                    ? new Date(repairItem.dateRepair.seconds * 1000)
                    : new Date(),
                }));
                const repairsWithDateB = matchedRepairsB.map((repairItem) => ({
                  ...repairItem,
                  dateRepair: repairItem.dateRepair
                    ? new Date(repairItem.dateRepair.seconds * 1000)
                    : new Date(),
                }));
                const sortedRepairsA = repairsWithDateA.sort(
                  (x, y) => y.dateRepair - x.dateRepair
                );
                const sortedRepairsB = repairsWithDateB.sort(
                  (x, y) => y.dateRepair - x.dateRepair
                );
                const latestRepairA = sortedRepairsA[0];
                const latestRepairB = sortedRepairsB[0];
                return (
                  (latestRepairA?.dateRepair || 0) -
                  (latestRepairB?.dateRepair || 0)
                );
              })
              .map((autoItem) => {
                const matchedRepairs = repair.filter(
                  (repairItem) =>
                    repairItem.gos === autoItem.gos &&
                    repairItem.categoryRepair === "ТО"
                );
                const repairsWithDate = matchedRepairs.map((repairItem) => ({
                  ...repairItem,
                  dateRepair: repairItem.dateRepair
                    ? new Date(repairItem.dateRepair.seconds * 1000)
                    : new Date(),
                }));
                const sortedRepairs = repairsWithDate.sort(
                  (a, b) => b.dateRepair - a.dateRepair
                );
                const latestRepair = sortedRepairs[0];
                const newDate =
                  latestRepair && latestRepair.dateRepair
                    ? new Date(latestRepair.dateRepair.getTime())
                    : null;
                if (newDate) {
                  newDate.setDate(newDate.getDate() + 45);
                }
                return (
                  <tr
                    key={autoItem.id}
                    className={`gos-calendar-table ${
                      newDate && newDate <= new Date()
                        ? "gos-select-techInspect"
                        : "white"
                    }`}
                  >
                    <td>
                      {latestRepair && newDate && newDate.toLocaleDateString()}
                    </td>
                    <td>
                      {latestRepair &&
                        latestRepair.dateRepair.toLocaleDateString()}
                    </td>
                    <td>
                      {latestRepair && latestRepair.mileage ? (
                        latestRepair.mileage
                      ) : (
                        <p className="t-gray-mini">Не вписан</p>
                      )}
                    </td>
                    <td>
                      <p className="gos">
                        {autoItem.gos.slice(0, 1)} {autoItem.gos.slice(1, 4)}{" "}
                        {autoItem.gos.slice(4, 6)}
                        <sup> {autoItem.gos.slice(6)}</sup>
                      </p>
                    </td>
                    <td>
                      <p className="chip-order-table-status">
                        {autoItem.statusAuto === "Ремонт" && (
                          <span className="chip-new_order">Ремонт</span>
                        )}
                        {autoItem.statusAuto === "Работает" && (
                          <span className="chip-succes_order">Работает</span>
                        )}
                        {autoItem.statusAuto === "Простой" && (
                          <span className="chip-wait_order">Простой</span>
                        )}
                        {autoItem.statusAuto === "ДТП" && (
                          <span className="chip-dtp_order">ДТП</span>
                        )}
                      </p>
                    </td>
                    <td>
                      {autoItem.marka} {autoItem.model}
                    </td>
                    <td>{latestRepair && latestRepair.categoryRepair} </td>
                    <td>{latestRepair && latestRepair.garage} </td>
                    <td>
                      <input
                        type="checkbox"
                        className="check-repair"
                        name="oilFilter"
                        checked={latestRepair && latestRepair.oilFilter}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="check-repair"
                        name="oilFilter"
                        checked={latestRepair && latestRepair.oilPlug}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="check-repair"
                        name="oilFilter"
                        checked={latestRepair && latestRepair.airFilter}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="check-repair"
                        name="oilFilter"
                        checked={latestRepair && latestRepair.cabinFilter}
                        disabled
                      />
                    </td>
                    <td>{autoItem.vin}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendarTO;
